import React from "react";

const Services = ({ classicHeader, darkTheme, handleNavClick }) => {
	// services details
	const services = [
		{
			name: "Cash transaction",
			desc: "$800",
			icon: "fas fa-money-bills",
		},
		{
			name: "PML transaction",
			desc: "$1,000",
			icon: "fas fa-people-arrows",
		},
		{
			name: "Seller Finance/Hybrid",
			desc: "$1,000",
			icon: "fas fa-person-shelter",
		},
		{
			name: "Gator transaction",
			desc: "$1,000",
			icon: "fas fa-puzzle-piece",
		},
		{
			name: "Standard Subject to transaction",
			desc: "$1,000",
			icon: "fas fa-hand-peace",
		},
		{
			name: "Post Closing Services",
			desc: "Varies by transaction",
			icon: "fas fa-check-double",
		},
	];

	return (
		<>
			<section
				id="services"
				className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
			>
				<div
					className={"container " + (classicHeader ? "" : "px-lg-6")}
				>
					{/* Heading */}
					<div className="position-relative d-flex text-center mb-3">
						<p
							className={
								"text-9  fw-600  w-100 align-self-center lh-base mb-0 " +
								(darkTheme ? "text-white" : "text-dark")
							}
						>
							TC Services
							<span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
						</p>
					</div>
					<div className="position-relative d-flex text-center mb-5">
						<p
							className={
								"fw-600 w-100 align-self-center lh-base mb-0 " +
								(darkTheme ? "text-white" : "text-dark")
							}
						>
							"Price is what you pay. Value is what you get.” –
							Warren Buffett
							{/* <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" /> */}
						</p>
					</div>
					{/* Heading end*/}
					{/* content start */}
					<div className="row">
						<div className="col-lg-10 mx-auto">
							<div className="row">
								{services.length > 0 &&
									services.map((service, index) => (
										<div className="col-md-6" key={index}>
											<div className="featured-box style-3 mb-5">
												<div
													className={
														"featured-box-icon text-primary  shadow-sm rounded " +
														(darkTheme
															? "bg-dark-1"
															: "bg-white")
													}
												>
													<i
														className={service.icon}
													/>
												</div>
												<h3
													className={
														darkTheme
															? "text-white"
															: ""
													}
												>
													{service.name}
												</h3>
												<p
													className={
														"mb-0 " +
														(darkTheme
															? "text-white-50"
															: "")
													}
												>
													{service.desc}
												</p>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
					{/* content end */}
				</div>
				<div className="d-flex justify-content-center mt-5">
					<a
						href="https://form.jotform.com/232278769267068"
						className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
						target="_blank"
						// onClick={(e) => {
						// 	e.preventDefault();
						// 	handleNavClick("contact");
						// }}
					>
						Submit A File
					</a>
				</div>
			</section>
		</>
	);
};

export default Services;
