import React from "react";
import resumeFile from "../documents/resume.pdf";
const AboutUs = ({ classicHeader, darkTheme }) => {
	return (
		<section
			id="about"
			className={"section " + (darkTheme ? "bg-dark-1" : "")}
		>
			<div className={"container " + (classicHeader ? "" : "px-lg-5")}>
				{/* Heading */}
				<div className="position-relative d-flex text-center mb-5">
					{/* <h2
						className={
							"text-24  text-uppercase fw-600 w-100 mb-0 " +
							(darkTheme
								? "text-muted opacity-1"
								: "text-light opacity-4")
						}
					>
						Why a TC?
					</h2> */}
					<p
						className={
							"text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
							(darkTheme ? "text-white" : "text-dark")
						}
					>
						Why a TC?
						<span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
					</p>
				</div>
				{/* Heading end*/}
				<div className="row gy-5">
					{/* About me content start */}
					<div className="col-lg-7 col-xl-8 text-center text-lg-start">
						<h2
							className={
								"text-7 fw-600 mb-3 " +
								(darkTheme ? "text-white" : "")
							}
						>
							At{" "}
							<span className="text-primary">Velocity TC,</span>
						</h2>
						<p className={darkTheme ? "text-white-50" : ""}>
							A Transaction Coordinator is a professional who
							plays a crucial role in facilitating and managing
							communication between all parties in a real estate
							transaction. Their primary goal is to ensure that
							the transaction process moves smoothly from start to
							finish, allowing investors and agents to focus on
							the core tasks that drive their business.
						</p>
						{/* <p className={darkTheme ? "text-white-50" : ""}>
							Delivering work within time and budget which meets
							client’s requirements is our moto. Lorem Ipsum has
							been the industry's standard dummy text ever when an
							unknown printer took a galley.
						</p> */}
					</div>
					{/* About me content end */}
					{/* about me personal detials start */}
					<div className="col-lg-5 col-xl-4">
						<div className="ps-lg-4">
							<ul
								className={
									"list-style-2 " +
									(darkTheme
										? "list-style-light text-light"
										: "")
								}
							>
								<li>
									<span className="fw-600 me-2">Name:</span>
									Esther Mbongo
								</li>
								<li>
									<span className="fw-600 me-2">Email:</span>
									<a href="mailto:esther@velocitytcservices.com">
										esther@velocitytcservices.com
									</a>
								</li>
								{/* <li>
									<span className="fw-600 me-2">Age:</span>28
								</li> */}
								{/* <li className="border-0">
									<span className="fw-600 me-2">From:</span>
									Los Angeles, California
								</li> */}
							</ul>
							{/* <a
								href={resumeFile}
								download
								className="btn btn-primary rounded-pill"
							>
								Download CV
							</a> */}
						</div>
					</div>
					{/* about me personal details end */}
				</div>
				{/* projects rewards counting start */}
				<div
					className={
						"brands-grid separator-border mt-5 " +
						(darkTheme ? "separator-border-light" : "")
					}
				>
					<div className="row">
						<div className="col-6 col-md-4">
							<div className="featured-box text-center">
								<h4
									className={
										"text-12  mb-0 " +
										(darkTheme
											? "text-white-50"
											: "text-muted")
									}
								>
									<span>10</span>+
								</h4>
								<p
									className={
										"mb-0 " +
										(darkTheme ? "text-light" : "")
									}
								>
									Helps you save up to 10+ hours per
									transaction
								</p>
							</div>
						</div>
						<div className="col-6 col-md-4">
							<div className="featured-box text-center">
								<h4
									className={
										"text-12  mb-0 " +
										(darkTheme
											? "text-white-50"
											: "text-muted")
									}
								>
									<span>90</span>%
								</h4>
								<p
									className={
										"mb-0 " +
										(darkTheme ? "text-light" : "")
									}
								>
									Handles 90% of closing tasks
								</p>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="featured-box text-center">
								<h4
									className={
										"text-12  mb-0 " +
										(darkTheme
											? "text-white-50"
											: "text-muted")
									}
								>
									<span>100</span>%
								</h4>
								<p
									className={
										"mb-0 " +
										(darkTheme ? "text-light" : "")
									}
								>
									Sends 100% of documents through DocuSign
								</p>
							</div>
						</div>
						{/* <div className="col-6 col-md-3">
							<div className="featured-box text-center">
								<h4
									className={
										"text-12  mb-0 " +
										(darkTheme
											? "text-white-50"
											: "text-muted")
									}
								>
									<span>38</span>
								</h4>
								<p
									className={
										"mb-0 " +
										(darkTheme ? "text-light" : "")
									}
								>
									Get Awards
								</p>
							</div>
						</div> */}
					</div>
				</div>
				{/* projects rewards counting end */}
			</div>
		</section>
	);
};

export default AboutUs;
