import React from "react";

const Disclaimer = ({ darkTheme }) => {
	return (
		<div
			id="disclaimer"
			className="modal fade"
			role="dialog"
			aria-hidden="true"
		>
			<div
				className="modal-dialog modal-lg modal-dialog-centered"
				role="document"
			>
				<div
					className={
						"modal-content " +
						(darkTheme ? "bg-dark-2 text-light" : "")
					}
				>
					<div className="modal-header">
						<h5
							className={
								"modal-title " + (darkTheme ? "text-white" : "")
							}
						>
							Copyright &amp; Disclaimer
						</h5>
						<button
							type="button"
							className={
								"btn-close " +
								(darkTheme ? "btn-close-white" : "")
							}
							data-bs-dismiss="modal"
							aria-label="Close"
						/>
					</div>
					<div className="modal-body p-4">
						{/* <p>
							No Agency: Nothing in this message shall constitute
							Velocity TC Services LLC acting as a legal
							representative or agent of any other party, nor
							shall Velocity TC Services LLC have the right or
							authority to assume, create, or incur any liability
							or obligation of any kind, express or implied,
							against or in the name of or on behalf of any other
							party.
						</p> */}
						<ul className="lh-lg">
							<li>
								No Agency: Nothing in this message shall
								constitute Velocity TC Services LLC acting as a
								legal representative or agent of any other
								party, nor shall Velocity TC Services LLC have
								the right or authority to assume, create, or
								incur any liability or obligation of any kind,
								express or implied, against or in the name of or
								on behalf of any other party.
							</li>
							<li>
								No Legal Advice: Any information from Velocity
								TC Services LLC does not and is not intended to
								constitute legal advice; instead, all
								information, content, and materials provided are
								for general informational purposes only. Such
								information may include links to other
								third-party websites, which are only for the
								convenience of the reader, user, or browser;
								Velocity TC Services LLC does not recommend or
								endorse the content of third party sites.
							</li>
							<li>
								Credits/Refunds: There are no credits or
								refunds. Upfront fees and deposits are
								non-refundable and cover work if the deal
								cancels. Final closing fees are only charged if
								the deal closes.
							</li>
							{/* <li>
								Quidam lisque persius interesset his et, Lisque
								persius interesset his et.
							</li>
							<li>
								Interesset his et, Lisque persius interesset his
								et, in quot quidam persequeris vim, ad mea
								essent possim iriure.
							</li>
							<li>
								Persius interesset his et, Lisque persius
								interesset his et, in quot quidam persequeris
								vim, ad mea essent possim iriure.
							</li>
							<li>
								Quot quidam persequeris vim Quidam lisque
								persius interesset his et, Lisque persius
								interesset his et, in quot quidam persequeris
								vim, ad mea essent possim iriure.
							</li> */}
						</ul>
						©2023 by Velocity TC Services LLC
					</div>
				</div>
			</div>
		</div>
	);
};

export default Disclaimer;
