import React from "react";
import Typewriter from "typewriter-effect";
import videobg from "../videos/home.mp4";

const Home = ({ classicHeader, darkTheme, handleNavClick }) => {
	return (
		<section id="home">
			<div className="hero-wrap">
				<div className="hero-mask opacity-8 bg-dark" />

				{/* ---------------image background------------------ */}
				<div
					className="hero-bg parallax"
					style={{ backgroundImage: 'url("images/home.png")' }}
				></div>

				{/* -------------------video background---------------------- */}

				{/* <div className="player hero-bg parallax">
          <video
            src={videobg}
            autoPlay
            muted
            loop
            style={{ width: "100%", height: "100vh", objectFit: "cover" }}
          ></video>
        </div> */}

				<div className="hero-content section d-flex min-vh-100">
					<div className="container my-auto">
						<div className="row">
							<div className="col-12 text-center">
								<p className="text-7 fw-500 text-white mb-2 mb-md-3">
									Velocity TC
								</p>
								{/* <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" /> */}
								<h2 className="text-16 fw-600 text-white mb-2 mb-md-3">
									<Typewriter
										options={{
											strings: [
												"Skip the paperwork",
												"Regain your focus",
												"Maximize your outcomes",
											],
											autoStart: true,
											loop: true,
										}}
									/>
								</h2>
								<p className="text-5 text-light mb-4">
									Enabling you to fulfill your purpose
								</p>
								<a
									href="https://form.jotform.com/232278769267068"
									className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-5 mx-3"
									target="_blank"
									// onClick={(e) => {
									// 	e.preventDefault();
									// 	handleNavClick("contact");
									// }}
								>
									Submit A File
								</a>
								<a
									href="#contact"
									className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-5"
									onClick={(e) => {
										e.preventDefault();
										handleNavClick("contact");
									}}
								>
									Book A Call
								</a>
							</div>
						</div>
					</div>
					<a
						href="#about"
						className="scroll-down-arrow text-white smooth-scroll"
						onClick={(e) => {
							e.preventDefault();
							handleNavClick("about");
						}}
					>
						<span className="animated">
							<i className="fa fa-chevron-down" />
						</span>
					</a>
				</div>
			</div>
		</section>
	);
};

export default Home;
