import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import { InlineWidget, PopupWidget, PopupButton } from "react-calendly";

import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "./Tooltip";

const Contact = ({ classicHeader, darkTheme }) => {
	const form = useRef();
	const [sendingMail, setSendingMail] = useState(false);

	const sendEmail = (e) => {
		e.preventDefault();
		setSendingMail(true);
		emailjs
			.sendForm(
				"service_i86k3ms",
				"template_si6cin9",
				form.current,
				"c9HsDgGF0tvWyVnAL"
			)
			.then(
				(result) => {
					document.getElementById("contact-form").reset();
					toast.success("Message sent successfully!", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: darkTheme ? "dark" : "light",
					});
					console.log(result.text);
					setSendingMail(false);
				},
				(error) => {
					toast.error("Something went wrong!", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: darkTheme ? "dark" : "light",
					});
					console.log(error.text);
					setSendingMail(false);
				}
			);
	};

	return (
		<section
			id="contact"
			className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
		>
			<div className="position-relative d-flex text-center mb-5">
				<p
					className={
						"text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
						(darkTheme ? "text-white" : "text-dark")
					}
				>
					{" "}
					Get in Touch
					<span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
				</p>
			</div>
			<div className={"container " + (classicHeader ? "" : "px-lg-5")}>
				<div className="col-md-8 col-xl-12 order-0 order-md-1 mb-5">
					<InlineWidget
						styles={{ height: "646px" }}
						url="https://calendly.com/esther_theinvestor?fbclid=IwAR2b69W4Tuj55Ak4ZdkC20DmGgSQb7mYiLfReLWFhu4zAO7wsi1DGeWhRgs"
					/>
				</div>
				{/* Heading */}
				{/* <div className="position-relative d-flex text-center mb-5">
					<p
						className={
							"text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
							(darkTheme ? "text-white" : "text-dark")
						}
					>
						{" "}
						Get in Touch
						<span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
					</p>
				</div> */}
				{/* Heading end*/}
				<div className="row gy-5">
					<div className="col-md-4 col-xl-4 order-0 order-md-0 text-center">
						<h2
							className={
								"mb-3 text-5 text-uppercase " +
								(darkTheme ? "text-white" : "")
							}
						>
							Partner with us on our next deal!
						</h2>
						<a
							href="https://form.jotform.com/232258993997075"
							class="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
							target="_blank"
						>
							I'm a Gator/PML
						</a>
					</div>

					<div className="col-md-4 col-xl-4 order-0 order-md-0 text-center">
						<h2
							className={
								"mb-3 text-5 text-uppercase " +
								(darkTheme ? "text-white" : "")
							}
						>
							get added to our VIP buyer's list!
						</h2>
						<a
							href="https://form.jotform.com/232267160824151"
							class="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
							target="_blank"
						>
							Join Now
						</a>
					</div>
					<div className="col-md-4 col-xl-4 order-0 order-md-0 text-center">
						<h2
							className={
								"mb-3 text-5 text-uppercase " +
								(darkTheme ? "text-white" : "")
							}
						>
							Leave a Review!
						</h2>
						<a
							href="https://form.jotform.com/232258728853163"
							class="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
							target="_blank"
						>
							Review
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contact;
