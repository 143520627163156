import React from "react";

const TermsAndConditions = ({ darkTheme }) => {
	return (
		<div
			id="terms-policy"
			className="modal fade"
			role="dialog"
			aria-hidden="true"
		>
			<div
				className="modal-dialog modal-lg modal-dialog-centered"
				role="document"
			>
				<div
					className={
						"modal-content " +
						(darkTheme ? "bg-dark-2 text-light" : "")
					}
				>
					<div className="modal-header">
						<h5
							className={
								"modal-title " + (darkTheme ? "text-white" : "")
							}
						>
							Terms &amp; Policy
						</h5>
						<button
							type="button"
							className={
								"btn-close " +
								(darkTheme ? "btn-close-white" : "")
							}
							data-bs-dismiss="modal"
							aria-label="Close"
						/>
					</div>
					<div className="modal-body p-4">
						{/* <p>
							Simply dummy text of the printing and typesetting
							industry. Lorem Ipsum has been the industry's
							standard dummy text ever since the 1500s, when an
							unknown printer took a galley of type and scrambled
							it to make a type specimen book. It has survived not
							only five centuries, but also the leap into
							electronic typesetting, remaining essentially
							unchanged.
						</p> */}
						<h3
							className={
								"mb-3 mt-4 mt-4 " +
								(darkTheme ? "text-white" : "")
							}
						>
							Terms of Use
						</h3>
						<p>
							These General Terms and Conditions ("Terms")
							constitute a legally binding agreement between you
							("User," "You," or "Your") and Velocity TC LLC
							("We," "Us," or "Our"), governing Your use of
							[www.velocitytcservices.com] and all its associated
							services (collectively referred to as the
							"Website"). By accessing or using the Website, You
							acknowledge that You have read, understood, and
							agree to be bound by these Terms. If You do not
							agree with these Terms, You must not use the
							Website.
						</p>
						<p>
							We reserve the right to modify, update, or replace
							these Terms at any time. Changes will become
							effective upon posting to the Website. Continued use
							of the Website after such changes constitutes Your
							acceptance of the modified Terms. It is Your
							responsibility to review these Terms periodically
							for updates.
						</p>
						<h5
							className={
								"text-4 mt-4 " + (darkTheme ? "text-white" : "")
							}
						>
							User Content
						</h5>
						<p>
							a. You may contribute content to the Website, such
							as comments, reviews, or user-generated content. By
							submitting content, You grant Us a non-exclusive,
							worldwide, royalty-free license to use, reproduce,
							modify, distribute, and display Your content for the
							purpose of operating and promoting the Website.
						</p>
						<p>
							b. You represent and warrant that Your submitted
							content does not violate any applicable laws,
							infringe on any intellectual property rights, or
							contain offensive, defamatory, or harmful material.
						</p>
						<h5
							className={
								"text-4 mt-4 " + (darkTheme ? "text-white" : "")
							}
						>
							Intellectual Property
						</h5>
						<p>
							All content, trademarks, logos, designs, and
							materials available on the Website are protected by
							intellectual property laws. You agree not to
							reproduce, distribute, modify, or create derivative
							works from such content without Our explicit written
							consent.
						</p>
						<h5
							className={
								"text-4 mt-4 " + (darkTheme ? "text-white" : "")
							}
						>
							Privacy
						</h5>
						<p>
							Your privacy is important to us. Our Privacy Policy
							outlines how We collect, use, and safeguard Your
							personal information. By using the Website, You
							consent to Our data practices as described in the
							Privacy Policy.
						</p>
						<h5
							className={
								"text-4 mt-4 " + (darkTheme ? "text-white" : "")
							}
						>
							Disclaimer of Warranties
						</h5>
						<p>
							The Website is provided "as is" and "as available."
							We make no warranties, express or implied, regarding
							the accuracy, reliability, completeness, or
							suitability of the content on the Website. Your use
							of the Website is at Your own risk.
						</p>
						<h5
							className={
								"text-4 mt-4 " + (darkTheme ? "text-white" : "")
							}
						>
							Limitation of Liability
						</h5>
						<p>
							To the extent permitted by law, We shall not be
							liable for any direct, indirect, incidental,
							special, consequential, or punitive damages arising
							from or related to Your use of the Website, even if
							We have been advised of the possibility of such
							damages.
						</p>
						<h5
							className={
								"text-4 mt-4 " + (darkTheme ? "text-white" : "")
							}
						>
							Indemnification
						</h5>
						<p>
							You agree to indemnify, defend, and hold Us harmless
							from any claims, liabilities, damages, losses, and
							expenses (including attorney fees) arising out of
							Your use of the Website, violation of these Terms,
							or infringement of any rights of a third party.
						</p>
						<h5
							className={
								"text-4 mt-4 " + (darkTheme ? "text-white" : "")
							}
						>
							Governing Law
						</h5>
						<p>
							These Terms shall be governed by and construed in
							accordance with the laws of [Jurisdiction], without
							regard to its conflicts of law principles.
						</p>
						<h5
							className={
								"text-4 mt-4 " + (darkTheme ? "text-white" : "")
							}
						>
							Termination
						</h5>
						<p>
							We reserve the right to suspend, terminate, or
							restrict Your access to the Website at Our
							discretion, without notice, for any reason,
							including violation of these Terms.
						</p>
						<h5
							className={
								"text-4 mt-4 " + (darkTheme ? "text-white" : "")
							}
						>
							Entire Agreement
						</h5>
						<p>
							These Terms constitute the entire agreement between
							You and Us regarding the Website, superseding any
							prior agreements or understandings.
						</p>
						<p>Effective Date: August 15, 2023</p>
						<p>
							By using the Website, You acknowledge that You have
							read, understood, and agreed to these General Terms
							and Conditions.
						</p>
						{/* <h3
							className={
								"mb-3 mt-4 " + (darkTheme ? "text-white" : "")
							}
						>
							Privacy Policy
						</h3>
						<p>
							Lorem Ipsum is simply dummy text of the printing and
							typesetting industry. Lorem Ipsum has been the
							industry's standard dummy text ever since the 1500s,
							when an unknown printer took a galley of type and
							scrambled it to make a type specimen book.
						</p>
						<ol className="lh-lg">
							<li>
								Lisque persius interesset his et, in quot quidam
								persequeris vim, ad mea essent possim iriure.
							</li>
							<li>
								Quidam lisque persius interesset his et, Lisque
								persius interesset his et, in quot quidam
								persequeris vim, ad mea essent possim iriure.
							</li>
							<li>
								In quot quidam persequeris vim, ad mea essent
								possim iriure. Quidam lisque persius interesset
								his et.
							</li>
							<li>
								Quidam lisque persius interesset his et, Lisque
								persius interesset his et.
							</li>
							<li>
								Interesset his et, Lisque persius interesset his
								et, in quot quidam persequeris vim, ad mea
								essent possim iriure.
							</li>
							<li>
								Persius interesset his et, Lisque persius
								interesset his et, in quot quidam persequeris
								vim, ad mea essent possim iriure.
							</li>
							<li>
								Quot quidam persequeris vim Quidam lisque
								persius interesset his et, Lisque persius
								interesset his et, in quot quidam persequeris
								vim, ad mea essent possim iriure.
							</li>
						</ol> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TermsAndConditions;
